const banner = new Swiper('#banner', {
  slidesPerView: 1,
  effect: 'fade',
  pagination: {
    el: '#banner-pagination',
    clickable: true,
  },
});

const models = new Swiper('#models', {
  slidesPerView: 'auto',
  spaceBetween: 40,
});

function isInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) - 80 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function startAnimation(to, wrapper) {
  setTimeout(() => {
    wrapper.style.transform = `translateY(-${to}px)`;
  }, 50);

  Promise.all([
    new Promise((resolve) =>
      wrapper.addEventListener('transitionend', resolve)
    ),
  ]);
}

const countersItem = document.querySelectorAll('.counter');

countersItem.forEach((counter) => {
  counter.textContent = '';
  const numbersToReach = counter.getAttribute('data-number').split('');

  numbersToReach.forEach((number) => {
    const numberWrapper = document.createElement('p');

    for (let i = 0; i <= number; i += 1) {
      const spanNumber = document.createElement('span');
      spanNumber.textContent = i;
      numberWrapper.append(spanNumber);
    }

    counter.appendChild(numberWrapper);
    const topOffset =
      numberWrapper.querySelectorAll('span')[
        numberWrapper.querySelectorAll('span').length - 1
      ].offsetTop;

    document.addEventListener('scroll', () => {
      isInViewport(counter) && startAnimation(topOffset, numberWrapper);
    });
  });
});
